import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../style.scss";
import { useHistory, useParams } from "react-router-dom";
export const TermsPage = () => {
  const history = useHistory();
    return <Row className="info-page" >
    <div className="main vertical-center">
    <Col md={12} className = "logo" >

    <a href onClick = {()=>history.push('/')}> occamm </a >
    </Col>

    <Col md={12} >
    <h3>
 Terms of Service 
  </h3>

  <br />
Effective from:     Nov 27, 2021

  <br />
Please read these terms of service ("terms", "terms of service") carefully before using occamm.com   operated by use. Whenever there are any changes made to our Terms of Service it will be reflected on this URL.


  <br />
  <br />
Conditions of Use

  <br />
We will provide the services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.

  <br />
  <br />
Privacy Policy

  <br />
Before you continue using our website we advise you to read our <a href = "/privacy/"> privacy policy </a> regarding our user data collection. It will help you better understand our practices.

  <br />
  <br />

Communications

  <br />
The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the Early Adopters Program on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.

  <br />
  <br />
Applicable Law

  <br />
By visiting this website, you agree that the laws of India, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between us and you, or our business partners and associates.

  <br />
  <br />
Disputes

  <br />
Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court of India and you consent to exclusive jurisdiction and venue of such courts.

  <br />
  <br />
Usage
  <br />
Visitors may use our services as long as it is not for illegal, defamatory, threatening purposes, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Only people who are 18 or above may use our services.

  <br />
We reserve all rights (but not the obligation) to remove  such content such as search history of a user and any other content they may have submitted.

  <br />
  <br />
License and Site Access

  <br />
We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.

  <br />
  <br />
User Account

  <br />
If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.

  <br />
We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
    </Col>
    </div>
    </Row>
}
