import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {createNewPage} from '../../../common/resultsStorage';
import { useHistory, useParams } from "react-router-dom";
import { PAGE_TYPE_REFINE} from "../../types";
import { getSummary, getEntities} from "../../actions";
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment';
import './style.scss';

let currentSelectedResultId;
const QueryInformation = ({spellSuggestion, q}) =>{

  const filters = useSelector(state => state.filters);
  const pageType = useSelector(state => state.pageType);
  const history = useHistory();
  const { id } = useParams();
  function doSearch(searchQuery) {
    createNewPage(parseInt(id) +1, searchQuery, undefined, filters);
    history.push(`/search/${parseInt(id)+1}`);
  };

  if (pageType === PAGE_TYPE_REFINE) return null;
  return <Row>


  <Col md={12} className={"spell"} >
  Showing results for <i>{spellSuggestion.alterationDisplayQuery}.</i> <span onClick={doSearch.bind(null, " \"" + spellSuggestion.originalQuery.trim()+ "\"")}> Searh for <i>{q} </i>  instead? </span>
  </Col>
</Row>
}

const NoResult =  () => {
  return <Row> <Col md={12} className={"spell"} >
Sorry, but we couldn't find any results!
    </Col>

</Row>


}
const WebResults = ({result}) => {
  const [currentTab, setCurrentTab ] = useState("preview");
  const {id } = useParams()
  useEffect(() => {
    setCurrentTab("preview")

  }
    , [id])
  const dispatch = useDispatch();

  const elementRef = useRef();

  const Summary = ({summary}) => {
    const summaryLoading = useSelector(s=>s.summaryLoadingInProgress) ;
    if(summaryLoading && currentSelectedResultId === result.id) return <Spinner animation="grow" />
    if(summary) return <div> {summary} </div>
    return "No summary available"
  }

  const Entities = ({entities}) => {

  const entitiesLoading = useSelector(s=>s.entitiesLoadingInProgress) ;
    if(entitiesLoading && currentSelectedResultId === result.id) return <Spinner animation="grow" />
    if(entities) return <div class="entities">
      {result.entities.map(e=> <div className= "entity"><span className="text2">{e.text}</span> <span className = "type"> {e.type} </span></div>)}
      </div>
    return ""
  }

  const Title = ({result}) =>   <div className={"title"} onClick={()=> {window.open(result.url,"_blank")}}>
    {result.title}
    </div>

  const DisplayUrl= ({result}) => <div className={"displayUrl"} onClick={()=> {window.open(result.url,"_blank")}}>
      {result.displayUrl}
    </div>

  function getCurrentClass(tab) {
    return currentTab === tab ? "active" : "inactive"
  }

  return <Col md={12} className={"web"} onClick={(e)=> {currentSelectedResultId = result.id }}>
    <div className="tab-bar">
       <div className = {`button ${getCurrentClass("preview")}`} onClick = { setCurrentTab.bind(null, "preview")}>Preview </div> 
       <div className = {`button ${getCurrentClass("summary")}`} onClick = {()=> { 
         if (result.summary === undefined) {
          dispatch(getSummary(result.url, result.id));
         }
         setCurrentTab("summary");
       }}>Summary</div> 
    </div>

    <div className={"text"}  >
      <Title result = {result} />
    {currentTab === "preview" ?  <div>
      {result.text}
    </div> : null }

    {currentTab === "summary"  ?  <div>
        

      <Summary summary = {result.summary}  />
    </div> :null}

    {currentTab === "entities"  ?  <div>

        <Entities entities = {result.entities} />
    </div> :null}

      <DisplayUrl result = {result} />
    </div>
  </Col> 
    
}

const NewsResults = ({results, q}) => {
  const [showMore, setShowMore] = useState(false);

  if (!results.length) return null;
  return <Col md={12} className={"news"} >
  <Row>
    <Col md = "12" >
    <div className={"heading"}>
    "{q}" in news
  </div>
    </Col>
    {
        results.map((news,index) => <Col md={12}>
          <div className={"newsBox"} onClick={() => {window.open(news.url, "_blank")}} 
          style={{display:( showMore? "block" :( index > 1 ? "none" : "block") )}}>
            <div className={"title"}>
              {news.title}
            </div>
          <div>
            {news.image && news.image.thumbnail.height < 500 ?

              <img src={news.image.thumbnail.contentUrl} />
              : null}
          </div>
            <span> {news.text.substr(0, 315)} </span>
            <br clear="all" />
            <div className={"info"}>
              <span className={"org"}> {news.provider[0].name}</span>
              <span className={"time"}><i>{moment(news.datePublished).fromNow()}</i></span>
            </div>
          </div>
        </Col>)
    }
  {showMore && results.length > 2 ? null :  <Col md = "12" >
    <Button onClick = {setShowMore.bind(null, true)} style = {{float: "right"}}> Show More </Button>

    </Col>
  }
  </Row>
  </Col>

}

const ResultBox = ({results, q}) => {
    const spellSuggestion = results.filter( r => r.type==="spell" && r.alterationOverrideQuery)[0];
  const searchInProgress = useSelector(state => state.resultsPanelSearchInProgress)

  return <Row className = {"result-box"}> 
    {results.length < 2 && searchInProgress == false ? <NoResult /> :null}
  {spellSuggestion? <QueryInformation spellSuggestion={spellSuggestion} q = { q }/> : null }
    {/* news */}
    <NewsResults results = {results.filter (r => r.type === "news")} q = {q} />
    {/* webpages */}
    {results.filter(r=>r.type === "webpages").map (r => < WebResults result = { r } />)
    }
    </Row> };

export function ResultsPanel() {
  const updatedResults = useSelector(state => state.resultsPanel)
  const searchInProgress = useSelector(state => state.resultsPanelSearchInProgress)
  const q = useSelector(state => state.qState.q)
  return <div className= "results-main" style = {searchInProgress === true ? {opacity:0.2} : {opacity:1}}>  <ResultBox results = {updatedResults} q={q}/> </div>


};
