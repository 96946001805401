import { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { loadSessionFromJson } from '../../../common/resultsStorage';
import { useHistory } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import "./style.scss";

function getLatestPageFromSession(sessionArray) {
  let maxId = -1;
  let lastPage = {};
  for (let page of sessionArray) {
    if (page.id > maxId) {
      maxId = page.id;
      lastPage = page;
    }
    else if (page.id == maxId) {
      if (page.tags.length) {
        lastPage = page;
      }
    }
  }
  return {...lastPage}
};
export function History({setGlideMargin}) {
  const navigationHistory = useHistory();
  const loadSession = (session, lastPageId) => {
    // clearPageData from current session storage
    // loop through each session page and create it in session storage
    loadSessionFromJson(session, navigationHistory);

  };
  const history = useSelector(state => state.history);
  const [currentPage, setCurrentPage] = useState({});
  useEffect(() => {
    if(Array.isArray(history) && history.length > 0) {
      setCurrentPage(getLatestPageFromSession(history[0]))

    }

  }, [history])
  const historyLoading = useSelector(state => state.historyLoadingInProgress);
  if (historyLoading === true)
  return <Row className="history">
    <Col md={12} >
      <div className="title">
<Spinner animation="grow" />
</div>

    </Col>
  </Row>

  if (history.length === 0) {
    setGlideMargin("33vh");
    return  null;
  }

    setGlideMargin("0");
  return <Row className="history">
    <Col md = {12} >
    <div className = "title">
      Resume your previous sessions
</div>
    </Col>
    <Col md={12}>
      {history.map((item, index) => { const lastPage = getLatestPageFromSession(item);
        return <span className={`number${lastPage.sessionId==currentPage.sessionId? " selected" : ""}`} onClick={setCurrentPage.bind(null, lastPage)}>
          {index + 1}
        </span>

      }

      )}

    </Col>

    <Col md={12}>
    { currentPage.preview!== undefined ?
      <div className="preview">
        <img src={currentPage.preview} onClick={loadSession.bind(null, history.find(s => s[0].sessionId === currentPage.sessionId), currentPage.id)} />
      </div> : null }
    </Col>
  </Row>
}
