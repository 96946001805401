import div from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { getCurrentPageId } from "../../common/util";
import { useLocation } from 'react-router-dom'
import { EAP } from '../EAP';

import "./style.scss";

export const Footer = () => {
  const location = useLocation();
  const VERBOSE = -1; 
 const [footerType, setFooterType] = useState(VERBOSE);
  useEffect(()=> {
     setFooterType(getCurrentPageId);
  }, [location])

  if (footerType == VERBOSE) {
  return <div className="footer">
<footer>
            <div class="container">
                <div class="row">

                    <div class="col-md-6 item text">
                        <h3>About Our Name</h3>
                        <p>The name OCCAMM is derived from the term Occam's Razor.</p>
                    </div>
                    <div class="col-md-6 item right">
                        <h3>About</h3>
                        <ul>
                            <li><a href="/privacy/">Privacy</a></li>
                            <li><a href="/terms">Terms</a></li>
                           
<li><a href="https://us5.list-manage.com/survey?u=df3fc8ae7eda8c079980f0507&id=52e6fbe027&attribution=false"> Contact Us</a></li>

                        </ul>
                    </div>
                    
                </div>
                <p class="copyright">©OCCAMM 2023 </p> <p class="tcd"> Made With ♥  By <a href = "https://www.theconsultingdeveloper.com" >TheConsultingDeveloper </a></p>
            </div>
        </footer>

  </div>
  }

  else {

return <div className = "footer slim">
              <center>
<a href="/privacy/">Privacy |</a>
<a href="/terms"> Terms |</a>
<a href="https://us5.list-manage.com/survey?u=df3fc8ae7eda8c079980f0507&id=52e6fbe027&attribution=false"> Contact Us</a>
                <span> | ©OCCAMM 2023 </span>
      </center>
      </div>
  }

};
