import { savePage, updateSessionId } from "./logging";
import { getCurrentPageId } from "./util";

// saves an entry for a new search
export function createNewPage(id, q, keywords, filters) {
  id = parseInt(id);
  const resultPages = JSON.parse(sessionStorage.getItem('resultPages')) || []
  // first remove all entries from id to resultPages.length;
  resultPages.length =id;
  // add current entry
  resultPages.push({
    q,
    keywords: (keywords || []),
    results: [],
    tags: [],
    filters
  });

  sessionStorage.setItem('resultPages', JSON.stringify(resultPages));
};


export function loadPage(id) {
  id = parseInt(id);
  let ret;
  const resultPages = JSON.parse(sessionStorage.getItem('resultPages'));
  if (resultPages) {
    ret = resultPages[id];
  }
  return ret;
};


export function updatePage(id, obj) {
  // if !currentpage
  id = parseInt(id);
  if (id != getCurrentPageId()) {

    console.log('rjec', id, getCurrentPageId(),)
    return;
  }
  const resultPages = JSON.parse(sessionStorage.getItem('resultPages'));
  resultPages[id] = {...resultPages[id], ...obj};
  sessionStorage.setItem('resultPages', JSON.stringify(resultPages));
  savePage({ ...resultPages[id], id })
  return

};

export function loadSessionFromJson(session, history) {
  sessionStorage.removeItem('resultPages');
  updateSessionId(session[0].sessionId);
  const sessionArraySorted = session.sort((p1, p2) => p1.id > p2.id);
  const sessionArray = [];
      for (const page of sessionArraySorted) {
        delete page.preview
        sessionArray[ page.id ] = page;
      }
        sessionStorage.setItem( "resultPages", JSON.stringify(sessionArray));
    for (const p of sessionArray) {
      if (p) {
      history.push('/search/' + p.id)
      }
    }
}
