import { create } from 'apisauce'
import { getCurrentPageId } from '../common/util';

const apiUrl = "/api/v1"
const api = create({
  baseURL: '' + apiUrl,
  headers: {Accept: 'application/json'},
})

export const getAPIObject = function (givenSessionId, givenPageId) {
  function isFresh() {
  const currentSessionId = sessionStorage.getItem("sessionId");
  const currentPageId = getCurrentPageId();
    console.log("data",givenSessionId,currentSessionId, givenPageId, currentPageId)
    return  currentPageId == givenPageId;
  }

  return {
    search: (q, filters) => {
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/search', {q: q,filters:filters});
    },
    extract: url =>{
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/extract', {url}).then(d => d.data).catch((e)=>{console.log(e); throw new Error()})
    },
    refine: (q, keywords, filters) => {
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/refine', {q: q, keywords: keywords, filters:filters})
    },
    suggest: (value) => { 
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/suggest', {value}, )},

    getSummary: (url) => {
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/summarize',{url}, )
    },

    getEntities: (url) => {
      if (!isFresh()) 
        return Promise.resolve({status:400})
      else  
      return api.get('/entities',{url}, )
    },

  }

};

export const EAPAPI = {
    join: (email) => api.get('/join', {email}).then(d=>d.data),
};
