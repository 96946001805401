import {RESULT_ITEMS, 
  RESULT_TAGS, 
  START_SEARCH, 
  END_SEARCH, 
  EMPTY_TAGS, 
  UPDATED_QUERY, 
  AUTOSUGGEST_ITEMS, 
  AUTOSUGGEST_EMPTY,
  SET_FILTERS,
  PAGE_TYPE_SEARCH,
  PAGE_TYPE_REFINE,
  SUMMARY_RESULT,
  ENTITIES_RESULT,
  SUMMARY_LOADING_STARTED,
  SUMMARY_LOADING_ENDED,
  ENTITIES_LOADING_STARTED,
  ENTITIES_LOADING_ENDED,
  HISTORY_LOADING_STARTED,
  HISTORY_RECIEVED,
} from './types';

export function resultsPanel(state = [], action) {
  switch (action.type) {
    case RESULT_ITEMS:
      state = [...action.items];
      break;
    case SUMMARY_RESULT: {
      const result = {...state[action.item.id]}
      result.summary = action.item.data;
      state = [...state]
      state[action.item.id] = result;
      break;
    }

    case ENTITIES_RESULT: {
      const result = {...state[action.item.id]}
      result.entities = action.item.data;
      state = [...state]
      state[action.item.id] = result;
      break;
    }
    // default:
    //   state = [];
  };

  return state;
}

export function resultsPanelSearchInProgress(state = false, action) {
  switch (action.type) {
    case START_SEARCH:
      state = true;
      break;

    case END_SEARCH:
      state = false;
      break;
    // default:
    //   state = [];
  };

  return state;
}

export function qState(state = {q:""}, action) {

  switch (action.type) {
    case UPDATED_QUERY:
      state = {q: action.q};
      break;
    default:
      // state = {q:""};
  };

  return state;
}

export function tagsPanel(state = [], action) {
  switch (action.type) {
    case RESULT_TAGS:
      state = [...state,...action.items];
      break;

    case EMPTY_TAGS:
      state = [];
      break;
    // default:
    //   state = [];
  };

return state;
};

export function autoSuggest(state = [], action) {

  switch (action.type) {
    case AUTOSUGGEST_ITEMS:
      state = [...state,...action.items];
      break;

    case AUTOSUGGEST_EMPTY:
      state = [];
      break;
  };

return state;
};


export function filters(state = [], action) {
  switch (action.type) {
    case SET_FILTERS:
      state = action.items;
      break;

    // default:
    //   state = [];
  };

return state;
};

export function pageType(state = PAGE_TYPE_SEARCH, action) {
  switch (action.type) {
    case PAGE_TYPE_SEARCH:
      state = PAGE_TYPE_SEARCH;
      break;

    case PAGE_TYPE_REFINE:
      state = PAGE_TYPE_REFINE;
      break;
    // default:
    //   state = [];
  };

return state;
};

export function summaryLoadingInProgress(state = false, action) {
  switch (action.type) {
    case SUMMARY_LOADING_STARTED:
      state = true;
      break;

    case SUMMARY_LOADING_ENDED:
      state = false;
      break;
    // default:
    //   state = [];
  };

  return state;
}

export function entitiesLoadingInProgress(state = false, action) {
  switch (action.type) {
    case ENTITIES_LOADING_STARTED:
      state = true;
      break;

    case ENTITIES_LOADING_ENDED:
      state = false;
      break;
    // default:
    //   state = [];
  };

  return state;
}


export function history(state = [], action) {
  switch (action.type) {
    case HISTORY_RECIEVED:
      state = [ ...action.items ];
      break;

    // default:
    //   state = [];
  };

return state;
};


export function historyLoadingInProgress(state = false, action) {
  switch (action.type) {
    case HISTORY_LOADING_STARTED:
      state = true;
      break;

    case HISTORY_RECIEVED:
      state = false;
      break;
    // default:
    //   state = [];
  };

  return state;
}

