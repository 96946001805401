import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

export const Logo = ()=> <Row> 

    <Col md = {{span:8, offset:3}}>

  <div className = "tag-line" >
  Research Engine
  </div>
<div className={"logo"}>
      OCCAMM
  </div>

    </Col>

    </Row>
