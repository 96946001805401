import {useState, useEffect} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useDispatch, useSelector} from 'react-redux';
import {createNewPage} from '../../../common/resultsStorage';
import { useHistory, useParams } from "react-router-dom";
import { Search } from 'react-bootstrap-icons';
import { Autosuggest } from '../../Autosuggest';
import { getAutosuggest } from '../../actions';
import { useDebouncedCallback } from 'use-debounce';
import { isMobileOrTab } from '../../../common/util';
import './style.scss';

export function TopPanel() {
  const newq = useSelector(state => state.qState.q)
  const filters = useSelector(state => state.filters)
  const [searchQuery, setSearchQuery] = useState(newq);
  const dispatch = useDispatch();
 const debounced = useDebouncedCallback(
    (value) => {
              dispatch(getAutosuggest(value))
    },
    0
  );
  useEffect(()=>{
  setSearchQuery(newq);
  },[newq])
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [id]);
  const doSearch = () => {
      
    if(searchQuery) {
    createNewPage(parseInt(id) +1, searchQuery, undefined, filters);
    history.push(`/search/${parseInt(id)+1}`);
    }
  };

  return <Row className={"topPanel"}>
    <Col md={12}>
    <span className = "logo" onClick = {()=>history.push('/')}> occamm </span>
    </Col>
      <Form onSubmit={(e) => {
        e.preventDefault();
        doSearch()
      }}>
        <Form.Group className="mb-10">

    <InputGroup className="mb-10">
          <Form.Control type="text" autoFocus = { isMobileOrTab()? false :true } value={searchQuery} onChange={(e) => {
            setSearchQuery(e.target.value)
            debounced(e.target.value);
          }}

    />

    <span className = {`input-group-text ${searchQuery?"clear-button": "clear-button hidden"}`} onClick={setSearchQuery.bind(null,"")}>x</span>
    <span className = "input-group-text search-button" onClick={doSearch}>
    <Search />
    </span>

    </InputGroup>

    <Autosuggest />
        </Form.Group>
      </Form>
  </Row>
}



