import {useSelector, useDispatch} from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import './style.scss';


export function RefineBox(props) {


  return <Row className ="refineBox" >
    <Col md={4}>
      <Button onClick={props.handler}  >Search</Button>{' '}
</Col>

      <Col md={8} className="message">
    <div className={props.class}>{props.message}</div>
      </Col>
  </Row>

}
