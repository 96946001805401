import {combineReducers} from 'redux';


import {resultsPanel,
  resultsPanelSearchInProgress,
  tagsPanel,
  qState,
  autoSuggest,
  filters,
  pageType,
  summaryLoadingInProgress,
  entitiesLoadingInProgress,
  history,
  historyLoadingInProgress,
} from '../components/reducer';



const rootReducer = combineReducers({
  resultsPanel,
  resultsPanelSearchInProgress,
  tagsPanel,
  qState,
  autoSuggest,
  filters,
  pageType,
  summaryLoadingInProgress,
  entitiesLoadingInProgress,
  history,
  historyLoadingInProgress,
});

export default rootReducer;
