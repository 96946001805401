import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {TopPanel} from './topPanel';
import {ResultsPanel} from './resultsPanel';
import {TagPanel} from './tagPanel';
import { useParams } from "react-router-dom";
import {useEffect } from "react";
import {useDispatch, } from 'react-redux';
import { getResults} from '../actions' ;

export function ResultsPage() {
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(getResults(id))
  })

  return <Container>

    <Row>
      <div style={{marginTop: '10px'}}> </div>
      <Col xl={6}>
        <TopPanel />

        <ResultsPanel />
      </Col>
      <Col xl={{span:5, offset:1}}>
    <TagPanel/>
      </Col>
    </Row>
  </Container>
}



