import 'bootstrap/dist/css/bootstrap.min.css';
import {ResultsPage} from './ResultsPage';
import {SearchPage} from './SearchPage';
import {Footer} from './Footer';
import {PrivacyPage} from './InfoPages/Privacy';
import {TermsPage} from './InfoPages/Terms';
import {useDispatch} from 'react-redux';
import {useEffect,useState} from 'react';
import Container from 'react-bootstrap/Container';
import {AUTOSUGGEST_EMPTY} from './types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function App() {
  // if there is already a query show the results page; else show the home page
  // const q = useSelector(state => state.qState.q)
  // const [currentQ, setCurrentQ] = useState(q);

  // useEffect(() => {
  //  setCurrentQ(q)
// },[q]);

  // return <Container>
  //   { currentQ == "" ? <SearchPage />: <ResultsPage />}
  // </Container>
  const dispatch = useDispatch();
  return <Router>
    <Container  onClick={() => dispatch({type: AUTOSUGGEST_EMPTY})}>
      <Switch>
        <Route path="/search/:id">
          <ResultsPage />
        </Route>

        <Route path="/terms">
          <TermsPage />
        </Route>

        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/">
          <SearchPage />
        </Route>



      </Switch>  

    </Container>
    <Footer />
    </Router>
}
