import {createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from './rootReducer';

const middleware = [ReduxThunk]
const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(...middleware)
));


export default store;

