import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import "./style.scss";
import { useHistory, useParams } from 'react-router-dom';
import {createNewPage} from '../../common/resultsStorage';

export const Autosuggest = () => {
  const { id } = useParams();
  const history = useHistory();

let items = useSelector(state => state.autoSuggest)
let filters = useSelector(state => state.filters)
  const doSearch = (searchQuery) => {
 const pageId = isNaN(id) ? 0 : id; 

      createNewPage(parseInt(pageId) + 1, searchQuery, undefined, filters);
      history.push(`/search/${parseInt(pageId) + 1}`);
  
  };
return <Row>
    <Col md="12 wrapper">
    <ul class="auto-suggest">
  {items.map((ele,key) => <li key= {key} onClick={()=>doSearch(ele.suggestedQuery)}> {ele.displayText} </li>)}
  </ul>
    </Col>
    </Row>

}
