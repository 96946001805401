import {useDispatch} from 'react-redux'
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import { Search } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {createNewPage} from '../../common/resultsStorage';
import { resetSession, createNewSession } from '../../common/logging';
import { useHistory } from "react-router-dom";
import { Logo } from './Logo';
import { Autosuggest } from '../Autosuggest';
import { getAutosuggest, getCurrentUserHistory } from '../actions';
import { History } from './History';
import { useDebouncedCallback } from 'use-debounce';
import { isMobileOrTab } from '../../common/util';
import {SET_FILTERS} from '../types';
import './style.scss';

export const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [glideMargin, setGlideMargin] = useState("33vh");
  let dispatch = useDispatch();
  let history = useHistory();
  // get user history
 // useEffect(() => {
   // if(! isMobileOrTab())
  //  dispatch(getCurrentUserHistory());
  // },[])
  const defaultFilters = ["webpages"]
    dispatch({type: SET_FILTERS, items: defaultFilters});
  const doSearch = () => {
    if(searchQuery) {
    resetSession();
    createNewPage(0, searchQuery,undefined, defaultFilters);
    history.push('/search/0')
    createNewSession();
    }
  };
 const debounced = useDebouncedCallback(
    (value) => {
              dispatch(getAutosuggest(value))
    },
    0
  );
  return <>
    <Row>
    <Col md = {12}>
    </Col>
    <div className={"vertical-center main"}>

    <Row className={"glide-down"} style = {{marginTop:glideMargin}}>
      <Logo/>
      <Col md={{span:6, offset:3}}>

        <Form onSubmit={(e) => {
          e.preventDefault();
          doSearch()
        }}>

    <InputGroup className="mb-3">
            <Form.Control type="text" autoFocus value={searchQuery} onChange={(e) => {
              setSearchQuery(e.target.value);
              debounced(e.target.value);
            }} 
    />

    <span className = {`input-group-text ${searchQuery?"clear-button": "clear-button hidden"}`} onClick={setSearchQuery.bind(null,"")}>x</span>
    <span className = "input-group-text search-button" onClick={doSearch}>
    <Search />
    </span>
          </InputGroup>

    <Autosuggest />
        </Form>
      </Col>
      <Col md={2}>

      </Col>
      <Row>
        <Col md={{span: 7, offset: 3}}>
        </Col>
      </Row>
    </Row>
    
    <History setGlideMargin = {setGlideMargin}/>
  </div>

    </Row>
    </>
}
