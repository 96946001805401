
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {RefineBox} from './RefineBox'
import './style.scss'
import { useHistory, useParams } from 'react-router-dom';
import { createNewPage } from '../../../common/resultsStorage';
const MAX_SELECT = 4;

export function TagPanel() {
  const tags = useSelector(state => state.tagsPanel);
  const q = useSelector(state => state.qState.q);
  const filters = useSelector(state => state.filters);
  const updatedResults = useSelector(state => state.resultsPanel)
  const {id} = useParams();
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState({});
  const [hoverClass, setHoverClass] = useState({});
  const [refineBoxMessage, setRefineBoxMessage] = useState('');
  const [refineBoxMessageClass, setRefineBoxMessageClass] = useState('');
useEffect(() => {
  // reset state when new tags are fetched
if( !tags.length) {
    setSelectedTags([]);
    setHoverClass([]);
}
}, [tags])
 function canSelectMore () {

      //checking is selected tags are under the limit
      let currentSelected = 0;
      Object.values(selectedTags).forEach(val => val ? currentSelected++ : currentSelected)
   return currentSelected < MAX_SELECT;
 }

  function toggleSelectTags(index) {

    const toggleValue = selectedTags["tag_" + index] ? false : tags[index];
    if (toggleValue) {
      if (!canSelectMore()) {
        // setRefineBoxMessage(`You can only select upto ${MAX_SELECT} tags`)
        // setRefineBoxMessageClass("error");
        // setTimeout(()=>{
// setRefineBoxMessage('');
// setRefineBoxMessageClass('');
        // },5000)
        return
      }


    }

      setSelectedTags({...selectedTags, ["tag_" + index]: toggleValue});
  }

  function selectHoverClass(index) {
    // if tag selected:
    let className = "";
    if (selectedTags["tag_" + index]) {
      className = "";
    }
    else {
    if (canSelectMore()){

      className = "canSelect"
    }
    else {
      className = "cantSelect"
    }
    }
    setHoverClass({...hoverClass, ["tag_" + index]: className})
      ;
  }

function  refineBoxButtonHandler () {
 // get selected tags
  const keywords = []; 
  Object.entries(selectedTags).forEach(([key,value]) => {

    if(value) {
keywords.push(value);
    }

  }) 
    if (keywords.length > 0) {
    createNewPage(parseInt(id) +1, q, keywords, filters);
    history.push(`/search/${parseInt(id)+1}`);
    }
 };
if (updatedResults.length < 2) return null;
  return <Row>
    <Col md={12} xl={12} className="tagBox">
      <span className="title">Expand your search By Selecting up to {MAX_SELECT} Keywords </span>
      <div class="tagWrapper">
  
        {tags.length? tags.map((t, index) => <span id={`tag_${index}`}
          onClick={() => toggleSelectTags(index)}
          onMouseEnter={() => selectHoverClass(index)}
          className={
            `tag fadeIn ${selectedTags['tag_' + index] ? 'selected' : ''}
        ${hoverClass['tag_' + index]}
        `}
        >{t}</span>) : <Spinner animation="grow" />}
      </div>
      <Col md={12}>
        <RefineBox handler = {refineBoxButtonHandler} message= { refineBoxMessage } class = {refineBoxMessageClass}/>
      </Col>
    </Col>
  </Row>;
}
