import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../style.scss";
import { useHistory, useParams } from "react-router-dom";
export const PrivacyPage = () => {

  const history = useHistory();
return <Row className="info-page" >
    <div className="main vertical-center">
    <Col md={12} className = "logo" >
    <a href onClick = {()=>history.push('/')}> occamm </a >
    </Col>

    <Col md={12} >
    <h3> Privacy Policy </h3>

  <br />
Effective from:     Nov 27, 2021

  <br />
This Privacy Policy explains how we (“OCCAMM” “we,” or “us”) collects, uses, and discloses information about you. This Privacy Policy applies when you use our websites, mobile applications, and other online products and services that link to this Privacy Policy (collectively, our “Services”), contact our customer service team, engage with us on social media, or otherwise interact with us.
<br/>
We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website or providing you with a notification). We encourage you to review this Privacy Policy regularly to stay informed about our information practices and the choices available to you.
  <br />
  <br />
  <br />
<h4> COLLECTION OF INFORMATION </h4>
We collect information you provide directly to us. For example, you share information directly with us when you create an account, fill out a form, submit or post content through our Services, purchase a membership, communicate with us via third-party platforms, request customer support, or otherwise communicate with us. The types of personal information we may collect include your name, email address, business information, and any other information you choose to provide. 
We do not collect payment information through our Services. We rely on third parties to process payments in connection with our Services. Any information you provide to facilitate such a payment is subject to the third-party payment processor’s privacy policy, and we encourage you to review this policy before you provide any information to the payment processor.

  <br />
  <br />
    Search History: <br />
We store your search queries, search results and any information you provide using our Editor. We use this information to provide a history of your search results.


  <br />
  <br />
  <br />
  <br />

    <h4>
Contact Us
    </h4>

If you have any questions about this Privacy Policy, please <a href= "https://us5.list-manage.com/survey?u=df3fc8ae7eda8c079980f0507&id=52e6fbe027&attribution=false">contact us using this form.</a>
    </Col>
    </div>
    </Row>


}
