export const RESULT_ITEMS = "RESULT_ITEMS";
export const UPDATED_QUERY = "UPDATED_QUERY";
export const RESULT_TAGS = "RESULT_TAGS";
export const EMPTY_TAGS = "EMPTY_TAGS";
export const START_SEARCH = "START_SEARCH";
export const END_SEARCH = "END_SEARCH";
export const AUTOSUGGEST_ITEMS = "AUTOSUGGEST_ITEMS";
export const AUTOSUGGEST_EMPTY = "AUTOSUGGEST_EMPTY";
export const SET_FILTERS = "SET_FILTERS";
export const PAGE_TYPE_SEARCH = "PAGE_TYPE_SEARCH";
export const PAGE_TYPE_REFINE = "PAGE_TYPE_REFINE";
export const SUMMARY_RESULT = "SUMMARY_RESULT";
export const ENTITIES_RESULT = "ENTITIES_RESULT";
export const SUMMARY_LOADING_STARTED = "TAB_LOADING_STARTED";
export const SUMMARY_LOADING_ENDED = "TAB_LOADING_ENDED";
export const ENTITIES_LOADING_STARTED = "ENTITES_LOADING_STARTED";
export const ENTITIES_LOADING_ENDED = "ENTITIES_LOADING_ENDED";
export const HISTORY_LOADING_STARTED = "HISTORY_LOADING_START";
export const HISTORY_RECIEVED = "HISTORY_RECIEVED";
