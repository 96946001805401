import {create } from 'apisauce';
import { toPng } from 'html-to-image';
import {useParams} from 'react-router-dom';

  const api = create({
    baseURL: '' + '/api/v1/auth',
    headers: {Accept: 'application/json'},
  })
 const login = () => api.post("/login")

// returns JWT token in a Promise
export const jwt = new Promise(async (resolve, reject) => {
  let jwt = localStorage.getItem('jwt');
  if (jwt) {
      resolve(jwt);
  } else {
    const response = await login();
    if (response.status === 200) {
      const jwt = response.data?.jwt
      localStorage.setItem("jwt", jwt)
      resolve(jwt);
    }
  }

}) 



//=======================================================
export const userAPI = (async function () {
 // const jwt = await login;
const api = create({
  baseURL: '' + '/api/v1/user',
  headers: {Accept: 'application/json',
    Authorization: (await jwt),
  },
})
return {
  postPage: (page)=> api.post ("/page", {page}),
  getHistory: ()=> api.post("/history"),
}
})()

let sessionId ;
export const resetSession = ()=> {
  sessionId = undefined;
  sessionStorage.removeItem("sessionId");
}

export const createNewSession  = async ()  => {
    const sessionId = Date.now().toString();
    sessionStorage.setItem("sessionId", sessionId)
};
export const updateSessionId = (newSessionId) => {

    sessionId = newSessionId;
    sessionStorage.setItem("sessionId", sessionId)
};
export const savePage =  async (page) =>  {
  // take screen shot
  document.getElementsByClassName("footer")[0].style.display = "none";
const node = document.body;
const preview = await toPng(node);
document.getElementsByClassName("footer")[0].style.display = "block";
      (await userAPI).postPage({ ...page, preview, sessionId: (sessionId || sessionStorage.getItem("sessionId")) })
};
