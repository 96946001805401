import {
  RESULT_ITEMS,
  RESULT_TAGS,
  START_SEARCH,
  END_SEARCH,
  EMPTY_TAGS,
  UPDATED_QUERY,
  AUTOSUGGEST_ITEMS,
  AUTOSUGGEST_EMPTY,
  SET_FILTERS,
  PAGE_TYPE_SEARCH,
  PAGE_TYPE_REFINE,
  SUMMARY_RESULT,
  ENTITIES_RESULT,
  SUMMARY_LOADING_STARTED,
  SUMMARY_LOADING_ENDED,
  ENTITIES_LOADING_STARTED,
  ENTITIES_LOADING_ENDED,
  HISTORY_LOADING_STARTED,
  HISTORY_RECIEVED,
} from './types';
import {getAPIObject} from '../common/api';
import { loadPage, updatePage} from '../common/resultsStorage';
import { userAPI } from '../common/logging'
import { getCurrentPageId } from '../common/util';

export const getResults = (id) => async (dispatch) => {


  const currentSessionId = sessionStorage.getItem("sessionId");
  const currentPageId = id;
  const API = getAPIObject(currentSessionId, currentPageId)

   dispatch({type: AUTOSUGGEST_EMPTY});
    dispatch({type: EMPTY_TAGS});
   dispatch({type: START_SEARCH});
  let q, keywords, results, tags, filters;
  // check if results are already stored  
  const storedResults = loadPage(id);
  if (!storedResults) {
    window.location.href = window.location.protocol + "//" + window.location.host ; 
  }
  ({q, keywords, results, tags, filters} = storedResults);
    dispatch({type: UPDATED_QUERY, q})
    dispatch({type: SET_FILTERS, items: filters});
  if (results.length > 0 ) {
    ({q, results, tags} = storedResults);

    dispatch({type: RESULT_ITEMS, items: results})
    dispatch({type: END_SEARCH});
    if (tags.length === 0) {
    const allTags = await loadTags(results);
    // save in storage
    updatePage(id, { tags: [...allTags] })
    }
    else {

        dispatch({type: RESULT_TAGS, items: tags});
    }
  }
  else {
    // not stored locally, fetch
    if(keywords.length === 0) {
      // search
    const  response = await API.search(q, filters.join(","));
      if (response.status === 200) {
        results = response.data;
     // assign ids, summary, and  entites values to each result
    results.forEach((ele,i) => ele.id = i)
    dispatch({type: RESULT_ITEMS, items: results})

      // save in storage
      updatePage(id, { results})

    dispatch({type: END_SEARCH});
    dispatch({type: PAGE_TYPE_SEARCH});
    dispatch({type:AUTOSUGGEST_EMPTY});
    const allTags = await loadTags(results);
    // save in storage
    updatePage(id, { tags: [...allTags]})
      }
    } else {
  // refine

    dispatch({type:EMPTY_TAGS});
    dispatch({type: PAGE_TYPE_REFINE});
    dispatch({type:AUTOSUGGEST_EMPTY});


  const response = await API.refine(q,keywords.join(","), filters.join(","));
      if(response.status=== 200) {
     // assign ids, summary, and  entites values to each result
     results = response.data
    results.forEach((ele,i) => ele.id = i)
   dispatch({type: RESULT_ITEMS, items: results})

      // save in storage
      updatePage(id, { results })
    dispatch({type: END_SEARCH});
    const allTags = await loadTags(results);
    // save in storage
    updatePage(id, { tags: [...allTags]})
    }

    }
  }

  async function loadTags(results) {
    // now fetching tags for each item in the result[]
    // but first empty the tag state array
    dispatch({type: EMPTY_TAGS});
    let allTags = [];
    for (let result of results) {
      tags = await API.extract(result.url);
      if (Array.isArray(tags)) {
        allTags = [...allTags, ...tags];
        dispatch({type: RESULT_TAGS, items: tags});
      }
    }
    return allTags;
  }
};

export const getAutosuggest = (value) => async (dispatch) => {
  const currentSessionId = sessionStorage.getItem("sessionId");
  const API = getAPIObject(currentSessionId, getCurrentPageId())

    dispatch({type:AUTOSUGGEST_EMPTY});
  if(value.length <= 3)
    return;
  let data = [];
  const response = await API.suggest(value); 
  if (response.status === 200 ) {
    data = response.data;
  }
  dispatch({type:AUTOSUGGEST_ITEMS, items:data})

};

export const getSummary = (url, id) => async (dispatch) => {

  const currentSessionId = sessionStorage.getItem("sessionId");
  const currentPageId = sessionStorage.getItem("pageId");
  const API = getAPIObject(currentSessionId, getCurrentPageId())

  dispatch({type:SUMMARY_LOADING_STARTED})
  let data;
  const response = await API.getSummary(url);
  if (response.status === 200)
    data = response.data;
  else
    data = "";

  dispatch({type:SUMMARY_LOADING_ENDED})
  dispatch({type: SUMMARY_RESULT, item: {id, data}})
}

export const getEntities = (url, id) => async (dispatch) => {

  const currentSessionId = sessionStorage.getItem("sessionId");
  const currentPageId = sessionStorage.getItem("pageId");
  const API = getAPIObject(currentSessionId, getCurrentPageId())

  dispatch({type:ENTITIES_LOADING_STARTED})
  let data;
  const response = await API.getEntities(url);
  if (response.status === 200)
    data = response.data;
  else
    data = [];

  dispatch({type:ENTITIES_LOADING_ENDED})
  dispatch({type: ENTITIES_RESULT, item: {id, data}})
}


export const getCurrentUserHistory = () => async (dispatch)  => {

  dispatch({ type: HISTORY_LOADING_STARTED });
 const response = await (await userAPI).getHistory();
  let data;
 if (response.status === 200) {
   data = response.data;

 }
  else  {
   data = [];
  }
  dispatch({type: HISTORY_RECIEVED, items:data});
}
